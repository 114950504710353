import opengl from "./OpenGLProject.vue"
import riscv from "./RiscVProject.vue"
import website from "./WebsiteProject.vue"
import dsp from "./DspProject.vue"
import astro from "./Astrophotography.vue"
import chip8 from "./SKChip8.vue"

// TODO:
// 1. make it so that any time i add a .vue file in this folder
//    and to this file, the parent projects.vue will add me to the projects page
export default {
    posts: [
        opengl,
        chip8,
        riscv,
        website,
        dsp,
        astro
    ]
}