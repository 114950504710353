<template>
    <div id="testDemo">

    </div>
</template>

<script>

export default {
    name: 'TestDemo',
}
</script>

<style scoped>
#testDemo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#testDemo p {
    width: 70%;
    margin: 0 auto;
    text-align: left;
    text-indent: 5%;
}
</style>
