<template>
    <div id="demoContainer">
        <h1> Demos </h1>
        <div class="component-buttons">
            <button v-for="(tab, key) in tabs"
                :key="key"
                @click="currentTab=tab.component">
                {{ tab.name }}
            </button>
        </div>
        <component :is="currentTab"/>
    </div>
</template>

<script>

import OpenGLDemo from './demos/OpenGLDemo.vue'
import WebGLDemo from './demos/WebGLDemo.vue'

export default {
    name: 'Demos',
    data() {
        return {
            currentTab: 'WebGLDemo',
            tabs: [
                {name: "WebGL Demo", component: 'WebGLDemo'}
                //{name: "OpenGL Demo", component: 'OpenGLDemo'}
            ]
        }
    },
    components: {
        WebGLDemo,
        OpenGLDemo
    }
}

</script>

<style scoped>

#demoContainer {
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100%;
}

.component-buttons {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.component-buttons button {
    color: #e6e6e6;
    font-size: 1em;
    background-color: #2c3e50;
    min-width: fit-content;
}

.component-buttons button:hover {
    background-color: #f1bc48;
}
</style>