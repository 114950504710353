<template>
    <h1> Projects </h1>

    <div id="projectContainer">
        <p class=noindent>
            Here are some of my projects. This include both school and personal projects, the former of which I often extend on my own
            after the course is over (when its interesting enough). It also includes some of my thoughts on each of them and the tools I used
            in creating them.
        </p>

        <!-- display all the projects -->
        <div v-for="project in projects" :key="project.name">
            <h2> 
                {{ project.name }} 
                <a v-if="'link' in project" v-bind:href="project.link.href" target="_blank">
                    {{ project.link.name }}
                </a>
            </h2>
            <component v-bind:is="project"/>
        </div>
    </div>

</template>

<script>

import projects from './projectSFC/index.js'

export default {
    name: 'Projects',
    data() {
        return {
            projects: projects.posts,
        }
    }
}

</script>

<style>

#projectContainer {
    display: flex;
    flex-direction: column;
}

#projectContainer p.noindent {
    margin-top:0;
    text-indent: 0%;
}

#projectContainer li {
    text-indent: 0;
    margin-bottom: 5px;
}

h2 {
    text-align: left;
}

</style>