<template>
  <div id="main">
    <div id="header" display:block>
      <img alt="Vue logo" src="./assets/insight.png" width="100" height="100" style="display:block;margin-right:auto;margin-bottom:4px">
      <div id="tabsList">
        <button v-for="(tab, key) in tabs"
          :key="key"
          @click="currentTab=tab">
          {{ tab }}
        </button>
      </div>
    </div>
    <div id="currentTabContent">
      <keep-alive>
        <component v-bind:is="currentTabComponent" />
      </keep-alive>
    </div>
  </div>
  <div id="footer">
      <a href="https://github.com/saifk00" target="_blank"> GitHub </a>
  </div>
</template>

<script>

import Demos from './components/Demos.vue'
import About from './components/About.vue'
import Projects from './components/Projects.vue'
import Readings from './components/Readings.vue'

export default {
  name: 'App',
  data() {
    return {
      currentTab: 'About',
      tabs: [
        'About',
        'Projects',
        'Demos',
        'Readings'
      ]
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab
    }
  },
  components: {
    Demos,
    About,
    Projects,
    Readings
  }
}

</script>

<style>
#app {
  font-family: Monaco;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5a8dc0;
}


#tabsList button {
  font-family: monospace;
  font-size: 20px;
  color: #f5d833;
  background-color: #480000;
  border: solid;
  padding: 5px;
  border-color: #140000;
}
#tabsList button:hover {
  background-color: #140000;
  cursor: pointer;
}

/* dark mode default!! */
body {
  background-color: #191919;
}

#tabsList {
  display:flex;
  flex-direction:row;
  align-content:left;
}

#main {
  margin-left: 10%;
  margin-right: 10%;
  background-color: #202123;
  min-height: 1000px;
}

#header {
  padding: 10px;
}

#currentTabContent {
  padding: 10px;
}

#footer {
  background-color: #140013;
  font-size: 20px;
  margin-left:10%;
  margin-right:10%;
  margin-top:5px;
  padding: 5px;
  display:block;
}

#footer a {
  color: #0088ff;
  text-decoration: none;
  padding: 5px;
}

a {
  color: #eec540;
}

p, li {
  width: 100%;
  margin: 5;
  text-indent: 5%;
  text-align: left;
  color: #cfcfcf;
}

</style>
