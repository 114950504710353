<template>
    <p>
        I had long avoided creating a website for myself, but I decided I had a few things to show and having people build them
        from scratch would be too annoying. I tried my hand using a static site generator but didn't like how high-level it was.
        Instead I decided to build this website from scratch using Vue.js for which I had to learn the fundamentals of web development. As it turns out its not as much of a
        headache as I had supposed (at least for something as simple as things), but I still feel more at 
        home in a linux terminal with some handy binutils. It took about a day to get this set up and I plan on improving it
        as I learn more.
    </p>
    <p class=noindent> 
        The most important thing I learned was that sometimes its better to stop getting hung up on doing something the perfect way the first time
        and to prioritize getting something working. For example, the DSP project above was initially meant to be embedded here as an iframe
        using <a href="https://www.nbinteract.com/">nbinteract</a> but after too much time messing around getting dependencies and file loading working
        (the last straw being dealing with multiple wsl kernel installations in windows) I decided to simply host my notebooks on their own repo and use
        <a href="https://mybinder.org/">mybinder</a> to generate a link.
    </p>
</template>

<script>

export default {
    name: "This Website"
}

</script>
