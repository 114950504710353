<template>
    <div id="demo">
        <h1>WebGL Demo</h1>
        <canvas id="webglDemoCanvas"/>
    </div>
</template>

<script>

var THREE = require('three');

export default {
    name: 'WebGLDemo',
    camera: null,
    scene: null,
    renderer: null,
    canvas: null,
    methods: {
        updateScene() {

        },
        animate() {
            requestAnimationFrame( this.animate );
            
            this.updateScene();

            this.renderer.render( this.scene, this.camera );
        },
        canvasResized() {
            var container = document.getElementById('demo');
            if (container) {
                var width = 0.5 * container.clientWidth;
                var height = width

                console.log('canvas resized: ' + width + 'x' + height);

                this.camera.aspect = width / height;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(width, height);
            }

        },
        initScene() {
            var width = 500;
            var height = 500;
            console.log(width, height);
            this.scene = new THREE.Scene();
            this.camera = new THREE.PerspectiveCamera( 75, width / height, 0.1, 1000 );

            this.renderer = new THREE.WebGLRenderer({canvas: this.canvas});
            this.renderer.setSize(width, height);

            const cube = new THREE.Mesh(
                new THREE.BoxGeometry(),
                new THREE.MeshBasicMaterial( { color: 0x00ff00, reflectivity: 0.2 } ) );
            const cube2 = new THREE.Mesh(
                new THREE.BoxGeometry(),
                new THREE.MeshBasicMaterial({color: 0x00ff00}));
            cube2.position.set(2, 2, -2);

            this.scene.add( cube );
            this.scene.add( cube2 );
            this.camera.position.z = 5;
            this.updateScene = function() {
                cube.rotation.x += 0.01;
                cube.rotation.y += 0.01;
            };

            console.log('animating');
            this.animate();
        }
    },
    mounted() {
        this.canvas = document.getElementById('webglDemoCanvas');
        this.initScene();

        var container = document.getElementById('demo');
        this.ro = new ResizeObserver(this.canvasResized)
            .observe(container);
    }
}
</script>

<style scoped>
#demo {
    display:flex;
    flex-direction:column;
    align-items:center;
    margin: 0 5%;
    width: 100%;
}
canvas {
    width: 80%;
    height:auto;
}

</style>

