<template>
    <h1> Reading List </h1>
    <p>
        In an inversion of Marx's 11th thesis on Feuerbach, Žižek once said 
        <i>
            "In the twentieth century, we maybe tried to change the world too quickly. The time is to interpret it again, to start thinking."
        </i>
        I don't know how true that is, and I have yet to write a Philosophy section here. But maybe there's something to be said about the modern tech industry (<a href="https://xkcd.com/1428/" target="_blank">Move fast and break things?</a>).
        Maybe not. Enough with the posturing; below is a list of books I've read
        or am in the process of reading. As much fun as it is to hack around to try and figure out an interface, I still think reading <a href="http://en.slow-media.net/manifesto" target="_blank">long form</a> content is the
        best way to learn complex concepts. It also promotes thinking before acting, which we could use a little more of in these times.
    </p>

    <div class="separator" text-align:center>  {{ separator }} </div>

    <div class="reading-list">
        <div class="reading-list-item" v-for="item in items.describedbooks" v-bind:key="item.title">
            <div class="reading-list-item-title">
                {{ item.title }}
            </div>
            <div class="reading-list-item-subtitle">
                {{ item.author }}
                ({{ item.date }})
            </div>
            <p class="reading-list-item-description" v-html="item.description"/>
        </div>
    </div>

    <div class="separator">  {{ separator }} </div>

    <p style="text-indent:0%;"> Here's some more books I've read or refer to often. Maybe they will help you too. </p>

    <div class="book-list">
        <div class="book-list-item" v-for="item in items.booklist" v-bind:key="item.title">
            <div class="reading-list-item-title">
                {{ item.title }}
            </div>
            <div class="reading-list-item-subtitle">
                {{ item.author }}
                ({{ item.date }})
            </div>
        </div>
    </div>

    <div class="separator"> {{ separator }} </div>

    <p>
        In Act III of the magical Bob Dylan song turned game <a href="https://store.steampowered.com/app/231200/Kentucky_Route_Zero_PC_Edition/" target="_blank">Kentucky Route Zero</a>,
        the player enters a cavern populated by a professor and his research team. What exactly they are researching is unclear, but they've been working on it for a long time.
        The centerpiece of the scene is <b>Xanadu</b>, a computer whose circuits have become coated with the mold that grows in the cave, and which may be poisoning the minds
        of the team (or represents it? I admit I'm not great at literary analysis). Originally designed as a revolutionary computer system, it has since fallen into decay. However, upon repairing
        it, the player finds the mold has given the computer abilities beyond the imagination of its original designers - the mold has given it some seed of chaos, and it has learned to
        perform a simulation of the events leading up to its own creation.
    </p>
    <p> 
        An amusing scene, undercut by my lackluster explanation. The name <b>Xanadu</b> is a reference to a lot of things. The most compelling of these is to the first hypertext project:
        <a href="https://www.wikiwand.com/en/Project_Xanadu" target="_blank"><b>Project Xanadu</b></a>. In the 1960s, long before even the concept of a word processor existed, Ted Nelson
        (who coined the term hypertext) had a vision echoed by the research team hidden away in the cave of Kentucky Route Zero. A vision vast in scope and often described in flowery language about
        freedom, individuality, and the future and potential of computing, Nelson claims that modern Hypertext is nothing but a simulacrum of paper, and a corruption of his original vision.
        The project never came to fruition, though the organization still exists and a 'working deliverable' was released in 2014. The key idea, whose essence continues on in the modern web,
        was that of a user journeying through a universe of text made up of references to other elements of said universe. So in the spirit of that vision, here are some links to stuff that I
        find interesting.
    </p>

    <div class="separator"> {{ separator }} </div>

    <div class="article-list">
        <div class="article-list-item" v-for="item in items.articles" v-bind:key="item.title">
            <div class="article-list-item-title">
                <a v-bind:href="item.link" target="_blank"> {{ item.title }} </a>
            </div>
            <p class="article-list-item-description" v-html="item.description"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Reading List',
    data() {
        return {
            separator: `◯◯◯===========================================================◯◯◯`,
            items: {
                // TODO: categorize these
                describedbooks: [
                    {
                        title: 'Effective Modern C++',
                        author: 'Scott Meyers',
                        date: '2014',
                        description: `
                            I've used C++ for quite some time now, but reading this was very eye opening.
                            Learning the details of language concepts like lvalues/rvalues, move semantics, type deduction, and so on
                            has clarified a lot of things that were a mystery to me and given me a new perspective on what this language is capable of.
                            This is a book I refer to often.
                        `
                    },
                    {
                        title: 'The C Programming Language',
                        author: 'Kernighan and Ritchie',
                        date: '1988',
                        description: `
                            This legendary text waas the first programming book I read. That choice may be questionable, but I still
                            think it is an excellent exposition of the language as well as general programming concepts. I would go on
                            but this book is such a classic that everything has probably already been said.
                        `
                    },
                    {
                        title: 'Cryptography Engineering',
                        author: 'Schneier, Ferguson, and Kohno',
                        date: '2010',
                        description: `
                            Written in part by the legendary <a href="https://www.schneier.com/">Bruce Schneier</a>, this was one of the first
                            books I read on the subject of cryptography and while I didn't really understand all of it at the time, it's a very
                            good introduction to the practical side of things and gives you an idea of the considerations that go into this
                            subtle and important subject.
                        `
                    },
                    {
                        title: 'Little Book of Semaphores',
                        author: 'Allen Downey',
                        date: '2016',
                        description: `
                            A short and sweet book covering some common synchronization problems and their solutions. Freely available
                            <a href="https://greenteapress.com/wp/semaphores/" target="_blank">online</a>, it's very good at motivating the
                            solutions rather than just presenting them.
                        `
                    },
                    {
                        title: 'Operating Systems: Three Easy Pieces',
                        author: 'Arpaci-Dusseau',
                        date: '2010',
                        description: `
                            Another fantastic book freely available <a href="https://pages.cs.wisc.edu/~remzi/OSTEP/" target="_blank">online</a>, this
                            book covers (duh) operating systems, dividing the vast subject into (duh, again) three pieces: virtualization,
                            concurrency, and persistence. This is one of the few books that I actually have a hard copy of due to it's
                            affordability (see the authors' <a href="http://from-a-to-remzi.blogspot.com/2014/01/the-case-for-free-online-books-fobs.html" target="_blank">relevant post</a>
                            regarding textbooks). I am not a fan of the dino book.
                        `
                    },
                    {
                        title: 'Computer Architecture',
                        author: 'Hennessy and Patterson',
                        date: '5e, 2012',
                        description: `
                            I've been skimming this book since highschool, waiting for the day I could finally approach it. The moment finally came in 2020,
                            and since then I've repeatedly gone back to re-read parts of it, paying closer attention to that parts that describe the history
                            of computer architecture, a subject I continue to be fascinated by.
                        `
                    }
                ],
                booklist: [
                    {
                        title: "The Linux Programming Interface",
                        author: "Michael Kerrisk",
                        date: '2010'
                    },
                    {
                        title: "Introduction to Algorithms",
                        author: "CLRS",
                        date: '3e, 2009'
                    },
                    {
                        title: "Introduction to Automata Theory, Languages, and Computation",
                        author: "Hopcroft, Motwani, and Ullman",
                        date: '3e, 2006'
                    },
                    {
                        title: "Understanding the Linux Kernel",
                        author: "Bovet and Cesati",
                        date: "3e, 2005"
                    },
                    {
                        title: "Computer Networking, A Top Down Approach",
                        author: "Kurose and Ross",
                        date: "6e, 2011"
                    },
                    {
                        title: "The Art of Electronics",
                        author: "Horowitz and Hill",
                        date: "7e, 2016"
                    },
                    {
                        title: "Concrete Mathematics",
                        author: "Graham, Knuth, and Patashnik",
                        date: "1994"
                    },
                    {
                        title: "Elementary Number Theory",
                        author: "Edwin Clark",
                        date: "2002"
                    },
                    {
                        title: "Visual Complex Analysis",
                        author: "Tristan Needham",
                        date: "2000"
                    },
                    {
                        title: "Visual Group Theory",
                        author: "Nathan Carter",
                        date: "2009"
                    },
                    {
                        title: "The Theoretical Minimum (Series)",
                        author: "Leonard Susskind",
                        date: "2013-2017"
                    },
                    {
                        title: "Introduction to Electrodynamics",
                        author: "David Griffiths",
                        date: "4e, 2013"
                    }
                ],
                articles: [
                    {
                        title: 'Functional Programming For The Rest of Us',
                        link: 'https://www.defmacro.org/2006/06/19/fp.html'
                    },
                    {
                        title: 'Architecture of Consoles',
                        link: 'https://www.copetti.org/writings/consoles/'
                    },
                    {
                        title: 'Linux Insides',
                        link: 'https://0xax.gitbooks.io/linux-insides'
                    },
                    {
                        title: 'OSDev',
                        link: 'https://wiki.osdev.org/Main_Page'
                    },
                    {
                        title: 'Computer Science from the Bottom Up',
                        link: 'https://www.bottomupcs.com/'
                    },
                    {
                        title: 'Learn OpenGL',
                        link: 'https://learnopengl.com/'
                    },
                    {
                        title: 'Eli Bendersky\'s website',
                        link: 'https://eli.thegreenplace.net/'
                    },
                    {
                        title: 'The Curse of Xanadu',
                        link: 'https://www.wired.com/1995/06/xanadu/'
                    },
                    {
                        title: 'Build Your Own X',
                        link: 'https://github.com/danistefanovic/build-your-own-x'
                    },
                    {
                        title: 'Physically Based Rendering',
                        link: 'https://www.pbr-book.org/'
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>

.book-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    margin: 0 auto;
}

.book-list-item .reading-list-item-title {
    text-align: left;
    font-size: 0.8em;
    color: rgb(236, 46, 46);
}
.book-list-item .reading-list-item-subtitle {
    text-align: right;
}

.reading-list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
}

.reading-list-item {
    flex-direction: column;
    width: 100%;
}

.reading-list-item-title {
    font-size: 1em;
    font-weight: bold;
    color: #49cbc2;
}

.reading-list-item-subtitle {
    font-size: 0.8em;
    color: #1684c9;
}

.reading-list-item-description {
    font-size: 0.8em;
    align-content: left;
}

.separator {
    font-size: 0.8em;
    color: #c9b501;
    padding: 0.5em;
    text-align: center;
}

.article-list {
    display: grid;
    grid-template-columns: repeat(5, 15%);
    justify-content: center;
    grid-gap: 1em;
}



</style>