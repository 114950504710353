<template>
    <div id="aboutmeContainer">
        <h1> About Me </h1>
        <p> 
            Hello. My name is Saif Khattak (Pashto خټک‎, IPA: xaˈʈak)
            I am a 4th year computer engineering student at the University of Waterloo.
            My technical interests include computer architecture, computer graphics, cryptography, hardware acceleration, FPGAs, compilers, and embedded systems.
            The image above is the item <b>Eyes of Corporate Insight</b> from the fantastic game <a href="https://store.steampowered.com/app/1388770/Cruelty_Squad/" target="_blank">Cruelty Squad</a>.
        </p>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style>


</style>